
export function isLightPage(url) {

    var pathUrl = url.replaceAll('/', '');

    console.log("Path == " + pathUrl)

    if (isEmpty(pathUrl) || pathUrl.endsWith("press") || pathUrl.endsWith("about-us") || pathUrl.endsWith("people1")
    || pathUrl.endsWith("people2") || pathUrl.endsWith("people3")
        || pathUrl.endsWith("careers") || pathUrl.endsWith("portfolio") || pathUrl.endsWith("blog") ) {
        return false
    }
    return true
}

export function hideMenu(url) {

    if (url.includes("/people/partner/") || url.includes("/people/advisor/") || url.includes("/people/mentor/") )
    {
        return true
    }
    return false
}

export function hideFooter(url) {

    if (url.includes("/people/partner/") || url.includes("/people/advisor/") || url.includes("/people/mentor/") )
    {
        return true
    }
    return false
}

export function isEmpty(value) {
    return (value == null || value === '');
}

export function scrollAnimation() {
    const callback = function (entries) {
        entries.forEach((entry) => {

            if (entry.isIntersecting) {
            //    console.log(entry.target.className);

                if (entry.target.className.includes('js-animate-fade-in-down')) {
                    entry.target.classList.add("animate-fade-in-down");
                }
                if (entry.target.className.includes('js-animate-fade-in-right')) {
                    entry.target.classList.add("animate-fade-in-right");
                }
                if (entry.target.className.includes('js-animate-fade-in-left')) {
                    entry.target.classList.add("animate-fade-in-left");
                }
                if (entry.target.className.includes('js-animate-fade-in-up')) {
                    entry.target.classList.add("animate-fade-in-up");
                }
                if (entry.target.className.includes('js-animate-fade-in-up-text')) {
                    entry.target.classList.add("animate-fade-in-up-text");
                }
                if (entry.target.className.includes('js-animate-vote')) {
                    entry.target.classList.add("animate-vote");
                }
                if (entry.target.className.includes('js-animate-pulse-on-scroll')) {
                    entry.target.classList.add("animate-pulse-on-scroll");
                }
                if (entry.target.className.includes('js-animate-fade-in-left-circle')) {
                    entry.target.classList.add("animate-fade-in-left-circle");
                }
                if (entry.target.className.includes('js-animate-fade-in-right-circle')) {
                    entry.target.classList.add("animate-fade-in-right-circle");
                }
                if (entry.target.className.includes('js-animate-fade-in-down-circle')) {
                    entry.target.classList.add("animate-fade-in-down-circle");
                }




            } else {
                // entry.target.classList.remove("animate-fade-in-left");
            }
        });
    };

    const observer = new IntersectionObserver(callback);

    const targets = document.querySelectorAll(".js-scroll");
    targets.forEach(function (target) {
        observer.observe(target);
    });
}