import React, { Fragment, useState, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
// import LogoDark from "../images/svg-icons/Logo2.svg" // Tell webpack this JS file uses this image
import LogoDark from "../images/svg-icons/logo_dark.svg";
import LogoLight from "../images/svg-icons/logo_white.svg";

import { Link } from "gatsby";

const solutions = [
  { name: "About", href: "/about-us" },
  { name: "People", href: "/people/1" },
  { name: "Portfolio", href: "/portfolio" },
  { name: "Press", href: "/press" },
  { name: "Careers", href: "/careers" },
  // { name: "Blog", href: "/blog" },
  { name: "Contact", href: "/contact-us" },
  { name: "Pitch to Us", href: "/pitch-to-us" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Menu(props) {
  const closeRef = useRef();
  return (
    <>
      <Popover
        className={`relative flex ${props.hideMenu ? "hidden" : ""}    ${
          props.isLightPage ? "bg-sky-50 h-32" : ""
        }`}
      >
        <>
          <Popover.Button className="absolute z-50 top-6 right-8 md:right-16  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-10 w-10 fill-current ${
                props.isLightPage ? "text-gray-700" : "text-gray-100"
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </Popover.Button>

          <Link to="/">
            {props.isLightPage ? (
              <LogoDark className="h-16 sm:h-20 w-auto  absolute z-50 top-6 left-8 md:left-16  " />
            ) : (
              <LogoLight className="h-16 sm:h-20 w-auto  absolute z-50 top-6 left-8 md:left-16 " />
            )}
          </Link>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-500 transform delay-200"
            enterFrom="opacity-0 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-500 transform delay-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-full"
          >
            {/* <Popover.Button ref={closeRef}> */}
            <Popover.Panel className="fixed z-50  w-full h-full sm:px-0 top-0  ">
              <Link to="/">
                <LogoLight className="h-16 sm:h-20 w-auto absolute top-6 left-8 lg:top-6 lg:left-6 z-50" />
              </Link>
              {/* <button onClick={() => closeRef.current?.click()} className="absolute top-8 lg:top-6 right-6 z-50 "> */}
              <XIcon className="text-white h-6 w-6 sm:h-10 sm:w-10 absolute top-8 lg:top-6 right-6 z-50" />
              {/* </button> */}

              <div className=" z-50 ring-black ring-opacity-5 overflow-hidden w-full bg-gradient-to-tr from-sky-400 to-sky-900 flex justify-center">
                <div className="h-100v w-full md:w-50% grid   px-16 py-28  lg:grid-cols-2">
                  {/* <div className="h-10v absolute left-10 top-10">
                                            <LogoLight className="h-20 " />
                                        </div> */}
                  {solutions.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="flex  items-center justify-center  rounded-lg text-gray-100 hover:text-orange-primary  transition ease-in-out duration-150"
                    >
                      <div className=" ">
                        <p className="font-banner-heading2 ">{item.name}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
            {/* </Popover.Button> */}
          </Transition>
        </>
      </Popover>
      {/* <div className={`absolute inset-0  top-28 z-10 w-full h-1 border-t  ${props.isLightPage ? 'border-gray-900' : 'text-gray-100'}`} /> */}
    </>
  );
}
