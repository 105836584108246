import React from "react";
import logo from "../images/site-icon.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "gatsby";

const notifySuccess = () =>
  toast.success("Thanks for subscribing to our newsletter", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
  });

const notifyFailed = () =>
  toast.error("Something went wrong", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
  });

const contextClass = {
  success: "bg-blue-600",
};

const initialValues = {
  email: "",
};

const onSubmit = (values, { resetForm }) => {
  const json = JSON.stringify(values);
  // console.log('Form submit', values)
  axios
    .post(process.env.GATSBY_REST_API_URL + `/newsletters`, json, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res);
      // console.log(res.data);
      notifySuccess();
    })
    .catch((error) => {
      notifyFailed();
      console.log(error.response.data.error);
    });
  resetForm();
};

const validationSchema = Yup.object({
  email: Yup.string().required("Required!").email("Invalid mail id"),
});

const navigation = {
  first: [
    { name: "About", href: "/about-us" },
    { name: "People", href: "/people/1" },
    { name: "Portfolio", href: "/portfolio" },
    { name: "Press", href: "/press" },
  ],

  second: [
    { name: "Careers", href: "/careers" },
    // { name: 'Blog', href: '/blog' },
    { name: "Contact", href: "/contact-us" },
    { name: "Pitch to Us", href: "/pitch-to-us" },
  ],
  social: [
    // {
    //   name: "Facebook",
    //   href: "https://www.facebook.com/pitchrightventures/",
    //   icon: (props) => (
    //     <svg
    //       className="w-6 h-6 text-blue-600 fill-current"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 24 24"
    //     >
    //       <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
    //     </svg>
    //   ),
    // },
    // {
    //   name: "Twitter",
    //   href: "https://twitter.com/pitchright",
    //   icon: (props) => (
    //     // <svg
    //     //   className="w-6 h-6 text-blue-300 fill-current"
    //     //   xmlns="http://www.w3.org/2000/svg"
    //     //   viewBox="0 0 24 24"
    //     // >
    //     //   <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
    //     // </svg>

    //     <svg
    //       id="Layer_2"
    //       className="w-6 h-6"
    //       data-name="Layer 2"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 252.9 252.9"
    //     >
    //       <g id="Layer_1-2" data-name="Layer 1">
    //         <g>
    //           <polygon points="69.94 64.66 162.39 188.24 183.32 188.24 90.88 64.66 69.94 64.66" />
    //           <path d="m126.45,0h0C56.61,0,0,56.61,0,126.45h0c0,69.84,56.61,126.45,126.45,126.45h0c69.84,0,126.45-56.61,126.45-126.45h0C252.9,56.61,196.29,0,126.45,0Zm31,198.12l-42.09-56.28-52.09,56.28h-13.39l59.5-64.28L50.25,54.78h45.57l38.76,51.83,47.97-51.83h13.39l-55.38,59.83,62.45,83.5h-45.57Z" />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    // },

    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/pitchright-ventures/",
      icon: (props) => (
        <svg
          className="w-6 h-6 text-blue-500 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
        </svg>
      ),
    },
  ],
};

export default function Footer(props) {
  return (
    <footer
      className={` ${props.hideFooter ? "hidden bg-sky-50" : "bg-white"}`}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="grid grid-cols-2 w-full ">
            <div>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.first.map((item) => (
                  <li key={item.name} className="font-body2">
                    <Link key={item.name} to={item.href}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="">
              <ul role="list" className="mt-4 space-y-4">
                {navigation.second.map((item) => (
                  <li key={item.name} className="font-body2">
                    <Link key={item.name} to={item.href}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="mt-8 xl:mt-4">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Sign up to our newsletter.
            </h3>

            <p className="mt-3 text-sm text-gray-400 tracking-normal">
              Stay up to date on our latest happenings.
            </p>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              <Form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <div>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-orange-primary focus:border-orange-primary focus:placeholder-gray-400"
                    placeholder="Enter your email"
                  />

                  <ErrorMessage name='email'>
                    {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                  </ErrorMessage>
                </div>

                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="w-full  bg-btnPrimary hover:bg-orange-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white  "
                  >
                    Subscribe
                  </button>
                </div>

              </Form>
            </Formik>
          </div> */}
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <Link to="/" className="mt-8 md:mt-0">
            <img src={logo} alt="Logo" className="w-12 h-12" />
          </Link>

          <p className="mt-8 text-center text-base text-gray-400">
            &copy; 2024. PitchRight Ventures. All rights reserved.{" "}
            <a href="/legal" target="_blank" className="underline">
              {" "}
              Terms of Use{" "}
            </a>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
}
