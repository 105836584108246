
import React from 'react'
import Menu from './menu'
import Footer from './footer'
import { isLightPage, hideMenu, hideFooter } from "../lib/utils"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from "react-cookie-consent";

const Layout = ({ location, children }) => {

    let isLightPageUrl = isLightPage(location.pathname);
    let hideMenuItem = hideMenu(location.pathname);
    let hideFooterItem = hideFooter(location.pathname);

    // console.log(location)

    return (
        <>
            <Menu hideMenu={hideMenuItem} isLightPage={isLightPageUrl} />
            <main className="bg-sky-50 ">{children}</main>
            <Footer hideFooter={hideFooterItem} />

            <ToastContainer style={{ width: "600px" }} />
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="Pitchrightcookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{background: "#f05a22", color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
        </>
    )
}

export default Layout